import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import studentsServices from "./studentsServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  studentsData: null,
  totalCount: 0,
  isLoadingforSearch: false,
  isErrorforSearch: false,
  messageforSearch: false,
  searchText: "",
  studentById: null,
  studentId: null,
  applicationData: null,
  applicationById: null,
  blogData: null,
  blogById: null,
  reviewsData: null,
  reviewById: null,
  contactUsData: null,
  isNewBlog: true,
};

export const getStudents = createAsyncThunk(
    "students/getStudents",
    async (params, thunkAPI) => {
      try {
        return await studentsServices.getStudents(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getStudentById = createAsyncThunk(
  "students/getStudentById",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getStudentById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getApplication = createAsyncThunk(
  "students/getApplication",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getApplication(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getApplicationById = createAsyncThunk(
  "students/getApplicationById",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getApplicationById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateApplication = createAsyncThunk(
  "students/updateApplication",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.updateApplication(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBlogs = createAsyncThunk(
  "blogs/getBlogs",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getBlogs(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addBlog = createAsyncThunk(
  "blogs/addBlog",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.addBlog(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReviews = createAsyncThunk(
  "review/getReviews",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getReviews(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blogs/updateBlog",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.updateBlog(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBlogById = createAsyncThunk(
  "blogs/getBlogById",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getBlogById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteReview = createAsyncThunk(
  "review/deleteReview",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.deleteReview(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReviewById = createAsyncThunk(
  "review/getReviewById",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getReviewById(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getContactUs = createAsyncThunk(
  "contact/getContactUs",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.getContactUs(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addReview = createAsyncThunk(
  "review/addReview",
  async (params, thunkAPI) => {
    try {
      return await studentsServices.addReview(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const studentsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setStudentsFilterDetails: (state, action) => {
      state.searchText = action.payload;
    },
    setStudentsById: (state, action) => {
      state.studentId = action.payload;
    },
    setReviewById: (state, action) => {
      state.reviewById = action.payload;
    },
    setSetIsNewBlog: (state, action) => {
      state.isNewBlog = action.payload;
    },
    setSetBlogById: (state, action) => {
      state.blogById = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getStudents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.studentsData = action.payload.data?.result;
      })
      .addCase(getStudentById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudentById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(getStudentById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.studentById = action.payload.data;
        state.message = action.payload.message;
      })
      .addCase(updateApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(updateApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(getApplication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(getApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.applicationData = action.payload.data.result;
      })
      .addCase(getApplicationById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApplicationById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(getApplicationById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.applicationById = action.payload.data;
      })
      .addCase(getBlogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.blogData = action.payload.data.result;
        state.totalCount = action.payload.data?.totalCount;
      })
      .addCase(updateBlog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(updateBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(getBlogById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlogById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(getBlogById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.blogById = action.payload.data;
      })
      .addCase(getReviews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.reviewsData = action.payload.data.result;
        state.totalCount = action.payload.data?.totalCount;
      })
      .addCase(deleteReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(getReviewById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReviewById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(getReviewById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.reviewById = action.payload.data;
      })
      .addCase(addBlog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(addBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(getContactUs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContactUs.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(getContactUs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.contactUsData = action.payload.data.result;
        state.totalCount = action.payload.data?.totalCount;
      })
      .addCase(addReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
  },
});

export const { 
  setReviewById,
  setSetBlogById,
  setSetIsNewBlog,
  setStudentsById,
  setStudentsFilterDetails } = studentsSlice.actions;

export default studentsSlice.reducer;
