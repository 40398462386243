import * as React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from '@mui/material/Radio';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { reset, login } from "../../redux/auth/authSlice";
import ccLogo from "../../assest/svgs/cc-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles( theme => ({
  inputBorder: {
    borderColor: '#000'
  }
}))
export default function SignInSide() {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, isError, message, isSuccess } = useSelector(
    (state) => state.auth
  );

  React.useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
    if (user?.statusCode === 400) {
      // toast.error(message);
      dispatch(reset());
    }
    if (user?.statusCode === 200) {
      toast.success(message);
      navigate("/");
      // dispatch(reset());
    }
  }, [user, isError, message, isSuccess, navigate, dispatch]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const validate = (e) => {
    const { name, value } = e.target;
    if (name === "email" && !validator.isEmail(value)) {
      setEmailError(true);
    }
    if (name === "email" && validator.isEmail(value)) {
      setEmailError(false);
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "password" && value.length === 0) {
      setPasswordError(true);
    }
    if (name === "password" && value.length > 0) {
      setPasswordError(false);
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const res = dispatch(login(formData));
    res?.then((r) => {
      if(r?.payload?.statusCode === 200){
        return;
      }
      toast.error(r?.payload?.message)
    })
  };

  // if (isLoading) return <Spinner />;
  return (
    <>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className="login-form"
      >
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{ backgroundColor: 'var(--primary-color)' }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          sx={{ alignSelf: "center !important" }}
        >
          <Box
            className="login-box"
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={ccLogo} style={{width: '100%'}} alt="logo" />
            <Typography 
              component="h1" 
              variant="h5" mt={5} mb={5}
              sx={{
                width: "100%",
                color: 'var(--secondary-color)',
                marginBottom: '12px',
                fontSize: '18px',
                fontWeight: '600'
              }}>
              Log In
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                id="email"
                name="email"
                type="email"
                label="Email Address"
                // value={email}
                onChange={validate}
                autoComplete="email"
                autoFocus
                margin="normal"
                className={classes.inputBorder}
                required
                error={emailError}
                helperText={emailError ? "Invalid Email" : ""}
                fullWidth
              />
              <TextField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                label="Password"
                //value={password}
                onChange={validate}
                autoComplete="current-password"
                margin="normal"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={passwordError}
                helperText={passwordError ? "required field" : ""}
                fullWidth
              />
              <FormControlLabel value="remember" control={<Radio />} label="Remember me" />
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                className="btn-grad"
                disabled={emailError || passwordError}
                sx={{ 
                  mt: 3, 
                  mb: 2,
                  backgroundColor: 'var(--primary-color)' }}
                loading={isLoading}
              >
                Log In
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
