import * as React from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Box,
  Toolbar,
  List,
  IconButton,
  ListItemText,
  CssBaseline,
  ListItemButton,
  Typography,
} from "@mui/material";
import CoachingIcon from "../../assest/images/coaching.png";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { LogoutOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import SchoolIcon from '@mui/icons-material/School';
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import Collapse from "@mui/material/Collapse";
import HandshakeIcon from '@mui/icons-material/Handshake';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import logoWhite from "../../assest/svgs/logoWhite.svg";
import ccWhiteLogo from "../../assest/svgs/ccWhiteLogo.svg";
import students from "../../assest/images/students.png";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RateReviewIcon from '@mui/icons-material/RateReview';
import HelpIcon from '@mui/icons-material/Help';
import WebIcon from '@mui/icons-material/Web';
import PhoneIcon from '@mui/icons-material/Phone';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Notification from "../../pages/notification/Notification";
import { logout, reset } from "../../redux/auth/authSlice";
import {setType} from "../../redux/universities/universitySlice";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Outlet } from "react-router-dom";
import routes from "../../react_routes_enum";
import "./layout.css";
import { 
  setUniversityByIdData
} from "../../redux/universities/universitySlice";
import { 
  setCoachingByIdData
} from "../../redux/coaching/coachingSlice";

const drawerWidth = 258;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "var(--primary-background-color)",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();

  const { route } = useSelector((state) => state.route);
  // console.log(route);
  ///Logic for dynamic text in header

  const makeFirstLetterCapital = (str) => {
    if (str.includes(" ")) {
      let a = str.split(" ");
      a = a.map((i) => makeFirstLetterCapital(i));
      return a.join(" ");
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  let arr = route.split("/");
  const nav = (arr) => {
    let str = "";
    let i = arr[0] === "" ? 1 : 0;
    for (i; i < arr.length - 1; i++) {
      if (arr[i].includes("_")) {
        str = str + makeFirstLetterCapital(arr[i].split("_").join(" ")) + " / ";
      } else {
        str = str + `${makeFirstLetterCapital(arr[i])} / `;
      }
    }

    return <span style={{ color: "grey" }}>{str}</span>;
  };

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(true);
  const [openCatalogue, setOpenCatalogue] = React.useState(false);
  const [openOrders, setOpenOrders] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationOpen, setNotificationOpen] = React.useState(false);
  const [openPartner, setOpenPartner] = React.useState(false);
  const [openLGS, setOpenLGS] = React.useState(false);
  const openPopup = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleCollapse = (id) => {
    console.log("id===",id)
    if (id === 1) {
      setOpenCatalogue(!openCatalogue);
      setOpenOrders(false);
      setOpenPartner(false);
      setOpenLGS(false);
    }
    if (id === 2) {
      setOpenPartner(false);
      setOpenCatalogue(false);
      setOpenLGS(false);
      setOpenOrders(!openOrders);
    }
    if (id === 3) {
      setOpenCatalogue(false);
      setOpenOrders(false);
      setOpenLGS(false);
      setOpenPartner(!openPartner);
    }
    if (id === 4) {
      setOpenCatalogue(false);
      setOpenOrders(false);
      setOpenPartner(false);
      setOpenLGS(!openLGS);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
  };
  const setDropdownType = (type) => {
    dispatch(setType(type))
  }

  const openNotification = () => {
    setNotificationOpen(!notificationOpen);
  }

  const toCapitalize = (str) => {
    let newStr = str === "sub_admins" ? "Sub Admins" : str[0]?.toUpperCase() + str.substring(1, str.length);
    return newStr;
  }
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar open={open} 
        sx={{ 
          paddingRight: "0 !important", 
          backgroundColor: "var(--primary-color)",
          width: open ? 'calc(100% - 240px)' :'calc(100% - 72px)' }} className="right-top-header">
        <Toolbar
          sx={{
            display: "flex",
            placeContent: "space-between",
            paddingLeft: "10px !important",
          }}
        >
          {/* Logo and sidebar toggle button */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              edge="start"
              sx={{
                ...(open && { display: "none" }),
              }}
            >
              <img src={logoWhite} alt="logo" />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={handleDrawer}
              className="menu-icon"
              sx={{
                marginRight: "16px",
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h1" noWrap sx={{fontSize: '25px'}}>
              {/* {nav(arr)} */}
              {/* Sub Admins */}
              {((location.pathname.split('/')[1] === 'help-&-support') ||
              (location.pathname.split('/')[1] === 'custom-pages') ||
              (location.pathname.split('/')[1] === 'new-custom-page') ||
              (location.pathname.split('/')[1] === 'lead-detail') ||
              (location.pathname.split('/')[1] === 'lead-generation-system') ||
              (location.pathname.split('/')[1] === 'contact-us')
              ) ? 
                <>
                {location.pathname.split('/')[1] === 'help-&-support' && 'Help & Support'}
                {location.pathname.split('/')[1] === 'custom-pages' && 'Custom Pages'}
                {location.pathname.split('/')[1] === 'new-custom-page' && 'New Custom Page'}
                {location.pathname.split('/')[1] === 'lead-detail' && 'Lead Detail'}
                {location.pathname.split('/')[1] === 'lead-generation-system' && 
                  'Lead Generation System'}
                {location.pathname.split('/')[1] === 'contact-us' && 'Contact Us'}
                </>
                : toCapitalize(location.pathname.split('/')[1])}
              
              {/* {makeFirstLetterCapital(arr[arr.length - 1].split("_").join(" "))} */}
            </Typography>
          </div>

          {/* User icon on top right and it's dropdown */}
          <div>
            <div style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center'
            }}>
              <IconButton
                color="inherit"
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                className="admin-pic"
              >
                {/* <Badge
                  badgeContent={user?.admin?.name || user?.data?.admin?.name}
                  color="primary"
                >
                  
                </Badge> */}
                <Avatar alt="user" />
              </IconButton>
              <div className="notification-icon" 
                style={{
                  position: 'relative',
                  marginRight: '85px'
                }} onClick={openNotification}>
                  <NotificationsNoneIcon />
                  <div style={{
                    backgroundColor: '#EF0000',
                    height: '16px',
                    width: '16px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: '7px',
                    right: '-2px'
                  }}>

                  </div>
              </div>
            </div>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openPopup}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutOutlined />
                </ListItemIcon>{" "}
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {/* Sidedrawer */}
      <Drawer variant="permanent" open={open} className="drawer">
        <DrawerHeader sx={{ alignSelf: "center" }}>
          <div style={{
            margin: "8px 0"
          }}>
            {open && <img 
              src={ccWhiteLogo} 
              alt="logo" 
              style={{ padding: "inherit", width: '135px' }} />}
          </div>
        </DrawerHeader>
        <List>
          <Link to={routes.DASHBOARD}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                <DashboardIcon sx={{color: '#fff'}} fontSize="large" />
              </ListItemIcon>
              <div>
                <ListItemText 
                  primary="DASHBOARD" 
                  sx={{
                    border: location.pathname === "/dashboard" ? "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                    fontSize: '20px'
                    }} className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>
          <Link to={routes.SUB_ADMINS}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                {/* <img src={postLogo} alt="" /> */}
                <AdminPanelSettingsIcon sx={{color: '#fff'}} fontSize="large" />
              </ListItemIcon>
              <div>
                <ListItemText 
                  primary="SUB ADMINS" 
                  sx={{
                    border: location.pathname === "/sub-admins" ? "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                    fontSize: '20px'
                    }} className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>

          <ListItemButton
            onClick={() => handleCollapse(1)}
            style={{ color: "white" }}
          >
            <ListItemIcon>
              {/* <img src={catalogueLogo} alt="" /> */}
              <SchoolIcon sx={{color: '#fff'}} fontSize="large" />
            </ListItemIcon>
            <ListItemText 
              primary="UNIVERSITY" 
              sx={{
                border: location.pathname.split('/')[1] === "university" ? 
                  "1px solid white" : 'none',
                borderRadius: '4px',
                padding: '0 4px',
              }}
              onClick={() => {
                dispatch(setCoachingByIdData(null));
                navigate(routes.UNIVERSITY_REQUEST)
                setDropdownType("University")
              }} 
              className="list-group-text" />
            {openCatalogue ? (
              <IndeterminateCheckBoxOutlinedIcon />
            ) : (
              <ArrowForwardIosIcon />
            )}
          </ListItemButton>
          <Collapse in={openCatalogue} timeout="auto">
            <List
              component="div"
              disablePadding
              sx={{ ...(!open && { display: "none" }) }}
            >
              {[
                { submenu: "Request", link: routes.UNIVERSITY_REQUEST },
                { submenu: "Registration", link: routes.UNIVERSITY_REGISTRATION },
                { submenu: "Review/Edit", link: routes.UNIVERSITY_REVIEW_EDIT },
                { submenu: "Approved", link: routes.UNIVERSITY_APPROVED },
                { submenu: "Rejected", link: routes.UNIVERSITY_REJECTED },
                { submenu: "Blocked", link: routes.UNIVERSITY_BLOCKED },
              ].map((item, i) => (
                <Link to={item.link} key={item.submenu}>
                  <ListItemButton
                    sx={{ pl: 6, color: "white" }}
                    selected={location.pathname === item.link}
                  >
                    <ListItemText primary={item.submenu} />
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Collapse>

          <ListItemButton
            onClick={() => handleCollapse(2)}
            style={{ color: "white" }}
          >
            <ListItemIcon>
              <img src={CoachingIcon} alt="coachingIcon" style={{width: "30px"}} />
            </ListItemIcon>
            <ListItemText primary="COACHING" 
              sx={{
                border: location.pathname.split('/')[1] === "coaching" ? 
                  "1px solid white" : 'none',
                borderRadius: '4px',
                padding: '0 4px',
              }}
              onClick={() => {
                dispatch(setUniversityByIdData(null));
                navigate(routes.COACHING_REQUEST)
                setDropdownType("Coaching")}
              } 
              className="list-group-text" />
            {openOrders ? (
              <IndeterminateCheckBoxOutlinedIcon />
            ) : (
              <ArrowForwardIosIcon />
            )}
          </ListItemButton>
          <Collapse in={openOrders} timeout="auto">
            <List
              component="div"
              disablePadding
              sx={{ ...(!open && { display: "none" }) }}
            >
              {[
                { submenu: "Request", link: routes.COACHING_REQUEST },
                { submenu: "Registration", link: routes.COACHING_REGISTRATION },
                { submenu: "Review/Edit", link: routes.COACHING_REVIEW_EDIT },
                { submenu: "Approved", link: routes.COACHING_APPROVED },
                { submenu: "Rejected", link: routes.COACHING_REJECTED },
                { submenu: "Blocked", link: routes.COACHING_BLOCKED },
              ].map((item, i) => (
                <Link to={item.link} key={item.submenu}>
                  <ListItemButton
                    sx={{ pl: 6, color: "white" }}
                    selected={location.pathname === item.link}
                  >
                    <ListItemText primary={item.submenu} />
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Collapse>

          <Link to={routes.STUDENT}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                <img src={students} alt="" width="28" />
              </ListItemIcon>
              <div>
                <ListItemText 
                  primary="STUDENTS" 
                  sx={{
                    border: (location.pathname.split('/')[1] === "students" || 
                    location.pathname.split('/')[1] === "student") ? 
                      "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                  }}
                  className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>
          <Link to={routes.APPLICATION_LIST}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                <AutoStoriesIcon sx={{color: '#fff', fontSize: '28px'}} />
              </ListItemIcon>
              <div>
                <ListItemText 
                  sx={{
                    border: (location.pathname.split('/')[1] === "applications" || 
                    location.pathname.split('/')[1] === "application") ? 
                      "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                  }}
                  primary="APPLICATIONS" 
                  className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>
          <Link to={routes.BLOGS}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                <MenuBookIcon sx={{color: '#fff', fontSize: '28px'}} />
              </ListItemIcon>
              <div>
                <ListItemText 
                  sx={{
                    border: (location.pathname.split('/')[1] === "blogs" || 
                    location.pathname.split('/')[1] === "blog") ? 
                      "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                  }}
                  primary="BLOGS" 
                  className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>
          <Link to={routes.TESTIMONIAL}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                <RateReviewIcon sx={{color: '#fff', fontSize: '28px'}} />
              </ListItemIcon>
              <div>
                <ListItemText 
                  sx={{
                    border: (location.pathname.split('/')[1] === "testimonials" || 
                    location.pathname.split('/')[1] === "testimonial") ? 
                      "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                  }}
                  primary="Testimonial" 
                  className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>
          <ListItemButton
            onClick={() => handleCollapse(3)}
            style={{ color: "white" }}
          >
            <ListItemIcon>
              {/* <img src={catalogueLogo} alt="" /> */}
              <HandshakeIcon sx={{color: '#fff'}} fontSize="large" />
            </ListItemIcon>
            <ListItemText 
              primary="PARTNERS" 
              sx={{
                border: location.pathname.split('/')[1] === "partner" ? 
                  "1px solid white" : 'none',
                borderRadius: '4px',
                padding: '0 4px',
              }}
              onClick={() => {
                dispatch(setCoachingByIdData(null));
                navigate(routes.PARTNER_REQUEST)
                setDropdownType("University")
              }} 
              className="list-group-text" />
            {openPartner ? (
              <IndeterminateCheckBoxOutlinedIcon />
            ) : (
              <ArrowForwardIosIcon />
            )}
          </ListItemButton>
          <Collapse in={openPartner} timeout="auto">
            <List
              component="div"
              disablePadding
              sx={{ ...(!open && { display: "none" }) }}
            >
              {[
                { submenu: "Request", link: routes.PARTNER_REQUEST },
                { submenu: "Review/Edit", link: routes.PARTNER_REVIEW },
                { submenu: "Registration", link: routes.PARTNER_REGISTRATION },
                { submenu: "Documents", link: routes.PARTNER_DOCUMENTS },
                { submenu: "Documents Review", link: routes.PARTNER_DOCUMENTS_REVIEW },
                { submenu: "Rejected", link: routes.PARTNER_REJECTED },
                { submenu: "Approved", link: routes.PARTNER_APPROVED },
              ].map((item, i) => (
                <Link to={item.link} key={item.submenu}>
                  <ListItemButton
                    sx={{ pl: 6, color: "white" }}
                    selected={location.pathname === item.link}
                  >
                    <ListItemText primary={item.submenu} />
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => handleCollapse(4)}
            style={{ color: "white" }}
          >
            <ListItemIcon>
              <GroupWorkIcon sx={{color: '#fff'}} fontSize="large" />
            </ListItemIcon>
            <ListItemText 
              primary="LGS" 
              sx={{
                border: location.pathname.split('/')[1] === "lead-generation-system" ? 
                  "1px solid white" : 'none',
                borderRadius: '4px',
                padding: '0 4px',
              }}
              onClick={() => {
                dispatch(setCoachingByIdData(null));
                navigate(routes.LGS_PENDING)
                setDropdownType("University")
              }} 
              className="list-group-text" />
            {openLGS ? (
              <IndeterminateCheckBoxOutlinedIcon />
            ) : (
              <ArrowForwardIosIcon />
            )}
          </ListItemButton>
          <Collapse in={openLGS} timeout="auto">
            <List
              component="div"
              disablePadding
              sx={{ ...(!open && { display: "none" }) }}
            >
              {[
                { submenu: "Pending", link: routes.LGS_PENDING },
                { submenu: "Rejected", link: routes.LGS_REJECTED },
                { submenu: "Approved", link: routes.LGS_APPROVED }
              ].map((item, i) => (
                <Link to={item.link} key={item.submenu}>
                  <ListItemButton
                    sx={{ pl: 6, color: "white" }}
                    selected={location.pathname === item.link}
                  >
                    <ListItemText primary={item.submenu} />
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Collapse>
          <Link to={routes.HELP_SUPPORT}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                <HelpIcon sx={{color: '#fff', fontSize: '32px'}} />
              </ListItemIcon>
              <div>
                <ListItemText 
                  sx={{
                    border: (location.pathname.split('/')[1] === "help-&-support") ? 
                      "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                  }}
                  primary="Help & Support" 
                  className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>
          <Link to={routes.CUSTOM_PAGES}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                <WebIcon sx={{color: '#fff', fontSize: '32px'}} />
              </ListItemIcon>
              <div>
                <ListItemText 
                  sx={{
                    border: (location.pathname.split('/')[1] === "custom-pages" ||
                    location.pathname.split('/')[1] === "new-custom-page") ? 
                      "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                  }}
                  primary="Custom Pages" 
                  className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>
          <Link to={routes.MBBS_ABROAD}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                <LocalHospitalIcon sx={{color: '#fff', fontSize: '32px'}} />
              </ListItemIcon>
              <div>
                <ListItemText 
                  sx={{
                    border: (location.pathname.split('/')[1] === "mbbs-abroad") ? 
                      "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                  }}
                  primary="MBBS Abroad" 
                  className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>
          <Link to={routes.CONTACT_US}>
            <ListItemButton style={{ color: "white" }}>
              <ListItemIcon>
                <PhoneIcon sx={{color: '#fff', fontSize: '32px'}} />
              </ListItemIcon>
              <div>
                <ListItemText 
                  sx={{
                    border: location.pathname.split('/')[1] === "contact-us" ? 
                      "1px solid white" : 'none',
                    borderRadius: '4px',
                    padding: '0 4px',
                  }}
                  primary="Contact Us" 
                  className="list-group-text" />
              </div>
            </ListItemButton>
          </Link>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, background: 'var(--primary-background)' }}>
        <DrawerHeader />

        {/* This will help putting other components between the layout perfectly */}
       
        <Outlet />
      </Box>
      <Notification 
        open={notificationOpen} 
        handleClick={() => setNotificationOpen(!notificationOpen)} />
    </Box>
  );
}
