const routes = Object.freeze({
  LOGIN: '/login',
  SUB_ADMINS: "/sub-admins",
  UNIVERSITY_REQUEST: '/university/requests',
  UNIVERSITY_REGISTRATION: '/university/registrations',
  UNIVERSITY_APPROVED: '/university/approved',
  UNIVERSITY_REJECTED: '/university/rejected',
  UNIVERSITY_LISTING_FORM: '/university/registration/form',
  UNIVERSITY_REVIEW_EDIT: '/university/edit',
  STUDENT: '/students',
  STUDENT_DETAILS: '/student/details',
  COACHING_REQUEST: '/coaching/requests',
  COACHING_REGISTRATION: '/coaching/registrations',
  COACHING_APPROVED: '/coaching/approved',
  COACHING_REJECTED: '/coaching/rejected',
  COACHING_LISTING_FORM: '/coaching/registration/form',
  COACHING_REVIEW_EDIT: '/coaching/edit',
  PRODUCT_LIST: '/product/list',
  NEW_PRODUCT: '/product/new_product',
  Edit_PRODUCT: '/product/edit',
  VIEW_PRODUCT: '/product/view',
  APPLICATION_LIST: '/applications/list',
  APPLICATION_DETAIL: '/application/detail',
  UNIVERSITY_BLOCKED: '/university/blocked',
  COACHING_BLOCKED: '/coaching/blocked',
  BLOGS: '/blogs',
  CONTACT_US: '/contact-us',
  BLOG_FORM: '/new-blog',
  TESTIMONIAL: '/testimonials',
  BLOG_DETAIL: '/blogs/detail',
  BLOG_EDIT: '/blog/edit',
  CREATE_TESTIMONIAL: '/testimonial/create-new',
  VIEW_TESTIMONIAL: '/testimonial/view',
  HELP_SUPPORT: '/help-&-support',
  HELP_SUPPORT_DETAIL: '/help-&-support/detail',
  CUSTOM_PAGES: '/custom-pages',
  MBBS_ABROAD: '/mbbs-abroad',
  NEW_CUSTOM_PAGE: '/new-custom-page',

  DETAILS: '/detail',
  CREATE_UNIVERSITY: '/university/registrations/create-new-university',
  INFORMATION: '/university/registrations/informations',
  UPLOAD: '/university/registrations/upload',
  COURSE: '/university/registrations/course',
  PLACEMENT_RECORD: '/university/registrations/placement-record',

  CREATE_COACHING: '/coaching/registrations/create-new-coaching',
  COACHING_INFORMATION: '/coaching/registrations/informations',
  COACHING_UPLOAD: '/coaching/registrations/upload',
  COACHING_COURSE: '/coaching/registrations/course',

  PARTNER_REQUEST: '/partner/requests',
  PARTNER_REVIEW: '/partner/review',
  PARTNER_REGISTRATION: '/partner/registrations',
  PARTNER_DOCUMENTS: '/partner/documents',
  PARTNER_DOCUMENTS_REVIEW: '/partner/documents/review',
  PARTNER_APPROVED: '/partner/approved',
  PARTNER_REJECTED: '/partner/rejected',
  PARTNER_DETAILS: '/partner/details',
  LGS_PENDING: '/lead-generation-system/pending',
  LGS_REJECTED: '/lead-generation-system/rejected',
  LGS_APPROVED: '/lead-generation-system/approved',
  LEAD_DETAIL: '/lead-detail',
  DASHBOARD: '/dashboard',

  CATALOGUE_MEAL: "/catalogue/meal",
});
export default routes;
