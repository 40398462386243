import { useState, useEffect, useCallback } from 'react';
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../react_routes_enum";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { InputLabel, TextField } from "@mui/material";
import debounce from "lodash.debounce";
import Loader from "../../assest/images/preloader.gif";
import CloseIcon from '@mui/icons-material/Close';
import { logout, reset } from "../../redux/auth/authSlice";
import { 
    getMBBSAbroadInfo,
    addMBBSAbroadInfo, 
    getCustomPages } from "../../redux/customPages/customPageSlice";

const useStyles = makeStyles( theme => ({
    fontbold: {
        fontSize: 20,
        marginTop: 0,
        marginBottom: 12,
        fontWeight: 'bold',
    },
    widthFit: {
        fontWeight: 'bold',
        cursor: 'pointer',
        width: 'fit-content',
    },
    root: {
        marginTop: 48,
        marginLeft: 24,
        marginRight: 24
    },
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    posRelative: {
        position: 'relative',
    },
    dropdown: {
        top: 85,
        zIndex: 1,
        padding: 8,
        height: 400,
        width: '30%',
        borderRadius: 4,
        overflowY: 'scroll',
        position: 'absolute',
        background: 'rgba(255, 255, 255)',
        border: '1px solid rgba(0, 0, 0, 0.4)',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
    },
    pageBox: {
        padding: 12,
        position: 'relative',
        border: '1px solid rgba(0, 0, 0, 0.4)'
    },
    addCollege: {
        fontSize: 16,
        color: '#fff',
        marginTop: 8,
        borderRadius: 4,
        fontWeight: 'bold',
        padding: '4px 8px',
        cursor: 'pointer',
        background: 'var(--primary-color)'
    },
    close: {
        background: 'none',
        border: 'none',
        position: 'absolute',
        right: 0,
        top: 12,
        cursor: 'pointer'
    },
    table: {
        width: '100%',
        marginTop: 12
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    removeBtn: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    btnSubmit: {
        color: '#fff',
        fontSize: 18,
        width: 120,
        fontWeight: 'bold',
        border: 'none',
        padding: '8px 12px',
        borderRadius: 4,
        float: 'right',
        marginTop: 16,
        marginBottom: 12,
        cursor: 'pointer',
        background: 'var(--primary-color)'
    }
}))

const MbbsAbroad = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState([]);
    const [tempData, setTempData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [customPageData, setCustomPageData] = useState([]);

    const { isLoading } = useSelector((state) => state.customPage);

    useEffect(() => {
        const res = dispatch(getCustomPages({ limit: 100, offset: 0, searchText }));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                setCustomPageData(r?.payload?.data?.result)
                return;
            }
            else if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                r?.payload?.message === "Session Expired"){
                toast.error("Session expired, please login again");
                localStorage.removeItem("hp_token");
                localStorage.removeItem("hp_user");
                navigate(routes.LOGIN);
                return;
            }
            else{
                toast.error(r?.payload?.message);
            }
        })
    },[searchText]);

    useEffect(() => {
        const res = dispatch(getMBBSAbroadInfo());
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                setFormData(r?.payload?.data?.json_value);
            }
        })
    },[]);

    const handleSearchText = (e) => {
        debouncedSave(e.target.value !== "" ? e.target.value : undefined)
    }

    const debouncedSave = useCallback(
        debounce((newValue) => setSearchText(newValue), 100),
        []
    );

    const addItem = (item) => {
        let array = [...formData];
        let filterData = array.filter((it) => { return item._id === it.customePageID });
        if(filterData.length > 0){
            toast.error("Already Added");
            return;
        }
        array.push({
            name: item.name,
            universités: [],
            customePageID: item._id,
        });
        setFormData([...array]);
    }

    const addNewCollege = (index) => {
        let newTempData = [...tempData];
        let array = [...formData];

        let checkIndex = tempData.findIndex((item) => { return item.index === index });
        let newObj = tempData[checkIndex];

        if(newObj?.name === "" || !newObj?.name){
            toast.error("Enter College or University Name");
            return;
        }
        if(newObj?.fees === "" || !newObj?.fees){
            toast.error("Enter Fees");
            return;
        }
        if(newObj?.duration === "" || !newObj?.duration){
            toast.error("Enter Duration");
            return;
        }
        let newArray = array?.map((item, i) => {
            let localArray = [...item.universités];
            localArray.push({
                name: newObj.name,
                fees: newObj.fees,
                duration: newObj.duration
            });
            if(i === index){
                return {
                    ...item,
                    universités: localArray
                }
            }
            return {...item}
        });
        newTempData.splice(checkIndex, 1);
        setTempData([...newTempData]);
        setFormData([...newArray]);
    }

    const handleTextChange = (e, i) => {
        let array = tempData;
        let filterData = array.filter((item) => { return item.index === i });
        if(filterData.length > 0){
            let obj = filterData[0];
            let checkIndex = array.findIndex((item) => { return item.index === i });
            e.target.name === `name-${i}` && (obj.name = e.target.value)
            e.target.name === `fees-${i}` && (obj.fees = e.target.value)
            e.target.name === `duration-${i}` && (obj.duration = e.target.value)
            array[checkIndex] = obj;
            setTempData([...array]);
        }
        else{
            let obj = {
                index: i,
                name: "",
                fees: "",
                duration: ""
            };
            e.target.name === `name-${i}` && (obj.name = e.target.value)
            e.target.name === `fees-${i}` && (obj.fees = e.target.value)
            e.target.name === `duration-${i}` && (obj.duration = e.target.value)
            array.push(obj);
            setTempData([...array]);
        }
    }

    const removeCollege = (index) => {
        let array = [...formData];
        array.splice(index, 1);
        setFormData([...array]);
    }

    const removeClg = (index, i) => {
        // let array = [...formData];
        // let newItem = formData[index];
        // let newArray = [...newItem.universités];
        // newArray.splice(i, 1);
        // setFormData([...array]);
        let array = [...formData];
        let newArray = array.map((item, ind) => {
            if(ind === index){
                let localArray = [...item.universités];
                localArray.splice(i, 1);
                return {
                    name: item.name,
                    universités: [...localArray],
                    customePageID: item.customePageID
                    
                }
            }
            return {...item}
        });
        setFormData([...newArray]);
    }

    const btnSubmit = () => {
        setIsBtnLoading(true);
        const res = dispatch(addMBBSAbroadInfo({json_value: formData}));
        res?.then((r) => {
            setIsBtnLoading(false);
            if(r?.payload?.statusCode === 200){
                toast.success("Data Updated Successfully");
                return;
            }
            if(r?.payload?.message.toLowerCase() === "unauthorized" || 
                r?.payload?.message === "Session Expired"){
                toast.error("Session expired, please login again");
                dispatch(logout());
                dispatch(reset());
                console.log("login")
                navigate(routes.LOGIN);
                return;
            }
            toast.error(r?.payload?.message);
        })
    }

    if (isLoading && !isBtnLoading) return <Spinner />;

    return (
        <div className={classes.root}>
            <div className={`${classes.posRelative} hoverE`}>
                <InputLabel className={classes.formInputLabel}>
                    Search Custom Page
                </InputLabel>
                <TextField
                    type="text"
                    variant="outlined" 
                    sx={{
                        width: '100%',
                        height: '62px'
                    }}
                    onChange={(e) => handleSearchText(e)}
                />
                <div className={`${classes.dropdown} pageDropdown`}>
                    {customPageData?.length > 0 && 
                        customPageData?.map((item, index) => (
                        <p 
                            key={index}
                            onClick={() => addItem(item)}
                            className={classes.widthFit}
                        >
                            {item?.name}
                        </p>
                    ))}
                </div>
            </div>
            {console.log("========forma>",formData)}
            {formData?.length > 0 &&
                formData?.map((item, index) => (
                    <div key={index} className={classes.pageBox}>
                        <p className={classes.fontbold}>
                            {index+1}. {item?.name}
                        </p>
                        <button 
                            type="button"
                            onClick={() => removeCollege(index)}
                            className={classes.close}
                        >
                            <CloseIcon />
                        </button>
                        <div>
                            <div>
                                <div>
                                    <InputLabel className={classes.formInputLabel}>
                                        University or College Name
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        name={`name-${index}`}
                                        onChange={(e) => handleTextChange(e, index)}
                                    />
                                </div>
                                <div>
                                    <InputLabel className={classes.formInputLabel}>
                                        Course Fees
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        name={`fees-${index}`}
                                        onChange={(e) => handleTextChange(e, index)}
                                    />
                                </div>
                                <div>
                                    <InputLabel className={classes.formInputLabel}>
                                        Course Duration
                                    </InputLabel>
                                    <TextField
                                        type="text"
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }}
                                        name={`duration-${index}`}
                                        onChange={(e) => handleTextChange(e, index)}
                                    />
                                </div>
                            </div>
                            <button 
                                type="button" 
                                onClick={() => addNewCollege(index)}
                                className={classes.addCollege}
                            >
                                Add College or University
                            </button>
                            {item?.universités?.length > 0 &&
                                <div>
                                    <table className={classes.table}>
                                        <thead>
                                            <tr>
                                                <td className={classes.heading}>
                                                    Sl No.
                                                </td>
                                                <td className={classes.heading}>
                                                    Name
                                                </td>
                                                <td className={classes.heading}>
                                                    Course Fees
                                                </td>
                                                <td className={classes.heading}>
                                                    Course Duration
                                                </td>
                                                <td className={classes.heading}>
                                                    
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item?.universités?.map((it, ind) => (
                                                <tr key={`${index}${ind}`}>
                                                    <td>{ind+1}</td>
                                                    <td>{it?.name}</td>
                                                    <td>{it?.fees}</td>
                                                    <td>{it?.duration}</td>
                                                    <td>
                                                        <button 
                                                            type="button"
                                                            onClick={() => removeClg(index, ind)}
                                                            className={classes.removeBtn}
                                                        >
                                                            <CloseIcon />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                ))
            }
            {formData?.length > 0 &&
                <button 
                    type="button"
                    disabled={isBtnLoading}
                    onClick={btnSubmit}
                    className={classes.btnSubmit}
                    style={{
                        cursor: isBtnLoading ? 'auto' : 'pointer',
                        background: isBtnLoading ? 'rgb(0, 0, 0, 0.3)' : 'var(--primary-color)',
                    }}
                >
                    {isBtnLoading ?
                    <img src={Loader} width="24" alt="loader" /> :
                    "Submit"}
                </button>
            }
        </div>
    )
}

export default MbbsAbroad;