import axios from "axios";
import endpoint from "../../index.routes";

const addNewCustomPages = async (params) => {
    const response = await axios.post(endpoint.CUSTOM_PAGE, params, {
      headers: { token: localStorage.getItem("hp_token") }, 
    });
    return response.data;
}

const getCustomPages = async (params) => {
    const { limit, offset, searchText } = params;
    const response = await axios.get(
      endpoint.CUSTOM_PAGE,
      {
        params: searchText ? { limit, offset, searchText } : { limit, offset },
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    return response.data;
};

const getCustomPagesById = async (params) => {
  const { id } = params;
  const response = await axios.get(
    endpoint.CUSTOM_PAGE + "/" + id,
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const deleteCustomPage = async (params) => {
    const { id } = params;
    const response = await axios.delete(
      endpoint.CUSTOM_PAGE + "/" + id,
      {
        headers: { token: localStorage.getItem("hp_token") },
      }
    );
    return response.data;
  };

const updateCustomPages = async (params) => {
  const { id, body } = params;
  const response = await axios.put(endpoint.CUSTOM_PAGE + "/" + id, body, {
    headers: { token: localStorage.getItem("hp_token") }, 
  });
  return response.data;
}

const getGroupList = async (params) => {
  const response = await axios.get(
    endpoint.GROUP_LIST,
    {
      headers: { token: localStorage.getItem("hp_token") },
    }
  );
  return response.data;
};

const addMBBSAbroadInfo = async (params) => {
  const response = await axios.post(endpoint.MBBS_ABROAD, params, {
    headers: { token: localStorage.getItem("hp_token") }, 
  });
  return response.data;
}

const getMBBSAbroadInfo = async (params) => {
  const response = await axios.get(endpoint.MBBS_ABROAD, {
    headers: { token: localStorage.getItem("hp_token") }, 
  });
  return response.data;
}

const customPageServices = { 
    addNewCustomPages,
    getCustomPages, 
    getCustomPagesById,
    updateCustomPages,
    deleteCustomPage,
    getGroupList,
    addMBBSAbroadInfo,
    getMBBSAbroadInfo
};

export default customPageServices;
