const COMMON = "https://bk.collegeclue.in/";
const URL_ENUM = {
  LOGIN: COMMON + "admin/login",
  SUBADMIN: COMMON + 'admin/sub-admin',
  UNIVERITY: COMMON + "admin/university",
  COACHING: COMMON + "admin/coaching",
  STUDENTS: COMMON + "admin/student", 
  ADDLISTING: COMMON + "admin/listing",
  PARTNER: COMMON + "admin/partner",
  ADD_REVIEW: COMMON + "admin/review",
  CUSTOM_PAGE: COMMON + "admin/custom-page",
  VEIRY: COMMON + "university/verify",
  GROUP_LIST: COMMON + "common/collegeGroup/list",
  UPDATE_UNIVERSITY: COMMON + "admin/university/edit",
  UPDATE_COACHING_DATA: COMMON + "admin/coaching/edit",
  SIGNUP_REQUEST: COMMON + 'admin/register-university',
  COACHING_SIGNUP_REQUEST: COMMON + 'admin/register-coaching',
  UNIVERISTY_BY_TOKEN: COMMON + "university/access-token",
  COACHING_BY_TOKEN: COMMON + "coaching/access-token",
  INSTITUTE_PROD: COMMON + "admin/product",
  STUDENT_APPLICATION: COMMON + "admin/student-application",
  BLOGS: COMMON + "admin/blog",
  REVIEWS: COMMON + "admin/review",
  LEAD: COMMON + "admin/lead",
  MBBS_ABROAD: COMMON + "common/generalJSON",
  CONFIG: COMMON + "university/configuration",
  UPLOAD_ON_S3: COMMON + "university/upload_data_on_s3",
  CONTACT_US: COMMON + "admin/studentContactUs"
};
export default URL_ENUM;
