import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from "@mui/styles";
import JoditEditor from 'jodit-react';
import UploadBox from '../../components/UploadBox/UploadBox';
import upload from '../../assest/svgs/upload-icon.svg';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import getImageUrls from "../../api/uploadImageOnS3";
import Loader from "../../assest/images/preloader.gif";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Moment from "moment";
import { 
    Button, 
    Typography, 
    InputLabel,
    Input,
    TextField } from "@mui/material";
import {
    setIsDropdown, 
    getUniversities } from "../../redux/universities/universitySlice";
import { getBlogs, getBlogById } from "../../redux/students/studentsSlice";

const useStyles = makeStyles( theme => ({
    formInputLabel:{
        color: '#000 !important',
        fontSize: '18px !important',
        marginBottom: '4px',
        fontWeight: 'bold !important'
    },
    btnSubmit: {
        backgroundColor: '#07AB35',
        color: '#fff',
        textTransform: 'capitalize',
        "&:hover":{
            backgroundColor: '#07AB35',
        }
    },
    featureBox: {
        width: 750,
        border: '1.5px solid rgb(0, 0, 0, 0.2)',
        padding: '12px 12px 0 12px',
        marginBottom: '12px'
    },
    addFeature: {
        background: '#F69220 !important',
        marginTop: '12px !important',
        color: '#FFF !important',
        textTransform: 'capitalize !important',
        borderRadius: '4px !important',
        "&:hover":{
            background: '#F69220',
        }
    },
    signleCard: {
        border: '1.4px solid rgb(0, 0, 0, 0.3)',
        padding: '12px'
    },
    upload: {
        border: '1px solid rgb(0, 0, 0, 0.2) !important',
        fontSize: '10px !important',
        color: 'rgb(0, 0, 0, 0.4) !important',
        textTransform: 'capitalize !important',
        width: '80px',
        height: '20px',
        marginBottom: '4px'
    },
    removItem: {
        color: "#EF0000 !important",
        fontWeight: 'bold !important',
        textTransform: 'capitalize !important',
        marginLeft: '12px !important',
        marginTop: '12px !important',
        borderColor: '#EF0000 !important', 
    }
}))

const CustomPageForm = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editor = React.useRef(null);
    const [blogList, setBlogList] = React.useState([]);
    const [totalUniversity, setTotalUniversity] = useState([]);

    // const { blogData } = useSelector((state) => state.student);
    const { universityData, isDropdown } = useSelector((state) => state.university);

    React.useEffect(() => {
        const res = dispatch(getUniversities({limit: 300, offset: 0, request_status: "accepted" }));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                setTotalUniversity(r?.payload?.data?.result);
            }
        })
    },[]);

    React.useEffect(() => {
        const res = dispatch(getBlogs({limit: 1000, offset: 0, status: 'accepted'}));
        res?.then((r) => {
            if(r?.payload?.statusCode === 200){
                setBlogList(r?.payload?.data?.result);
            }
        })
    },[])

    return (
        <div>
            {props.from === "banner_image" &&
                <>
                    <div style={{
                        display: 'flex',
                    }}>
                        <div>
                            <div style={{
                                display: 'flex'
                            }}>
                                <div>
                                    <InputLabel className={classes.formInputLabel}>
                                        Heading
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        name={`${props.from}_heading`} 
                                        variant="outlined" 
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        onChange={(e) => props.handleBChnage(e)} 
                                        value={props?.bannerDetail?.heading} 
                                        required/>
                                </div>
                                <div style={{marginLeft: '12px'}}>
                                    <InputLabel className={classes.formInputLabel}>
                                        CTA Button Name
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        name={`${props.from}_cta_button`} 
                                        variant="outlined" 
                                        sx={{
                                            width: '250px',
                                            height: '62px'
                                        }} 
                                        onChange={(e) => props.handleBChnage(e)}
                                        value={props?.bannerDetail?.button_name}  
                                        required/>
                                </div>
                            </div>
                            <div>
                                <InputLabel className={classes.formInputLabel}>
                                    Text
                                </InputLabel>
                                <TextField 
                                    placeholder="" 
                                    type="text" 
                                    name={`${props.from}_text`} 
                                    variant="outlined" 
                                    sx={{
                                        width: '100%',
                                        height: '62px'
                                    }}
                                    onChange={(e) => props.handleBChnage(e)}
                                    value={props?.bannerDetail?.text} 
                                    required/>
                            </div>
                        </div>
                        <div style={{marginLeft: '24px'}}>
                            <InputLabel className={classes.formInputLabel}>
                                Upload Image
                            </InputLabel>
                            <UploadBox
                                name="banner_image_image"
                                value={props?.bannerDetail?.image}
                                handleUpload={(name, value) => props.handleImageUploads(name, value)} 
                                />
                        </div>
                    </div>
                </>
            }
            {props.from === "about_course" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                        type="text" 
                        name={`${props.from}_title`}
                        variant="outlined" 
                        sx={{
                            width: '250px',
                            height: '62px'
                        }} 
                        onChange={(e) => props.handleBChnage(e)}
                        value={props?.aboutDetail?.title}
                        required/>
                    </div>
                    <div style={{
                        display: 'flex'
                    }}>
                        <div>
                            <InputLabel className={classes.formInputLabel}>
                                Heading
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                name={`${props.from}_heading`} 
                                variant="outlined" 
                                sx={{
                                    width: '250px',
                                    height: '62px'
                                }} 
                                onChange={(e) => props.handleBChnage(e)} 
                                value={props?.aboutDetail?.heading}
                                required/>
                        </div>
                        <div style={{marginLeft: '12px'}}>
                            <InputLabel className={classes.formInputLabel}>
                                CTA Button Name
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                name={`${props.from}_cta_button`} 
                                variant="outlined" 
                                sx={{
                                    width: '250px',
                                    height: '62px'
                                }} 
                                onChange={(e) => props.handleBChnage(e)}
                                value={props?.aboutDetail?.button_name}
                                required/>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '100%', marginBottom: '4%'}}>
                            <InputLabel className={classes.formInputLabel}>
                                Text Paragraph
                            </InputLabel>
                            <JoditEditor 
                                ref={editor}
                                value={props?.aboutDetail?.text}
                                onChange={newText => props.textEditor(newText, props.from)}
                            />
                        </div>
                        <div style={{marginLeft: '24px'}}>
                            <InputLabel className={classes.formInputLabel}>
                                Upload Image
                            </InputLabel>
                            <UploadBox
                                name="about_course_image"
                                value={props?.aboutDetail?.image}
                                handleUpload={(name, value) => props.handleImageUploads(name, value)}
                                />
                        </div>
                    </div>
                </>
            }
            {props.from === "features" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.featureDetail?.title}
                            required/>
                    </div>
                    {props.list?.map((item, mainIndex) => (
                        item?.section_type === "Features" &&
                        <>
                            {item.options.map((itm, i) => (
                                <div className={classes.featureBox} key={i} style={{
                                    marginTop: i === 0 ? '12px' : 0
                                }}>
                                    <div style={{display: 'flex'}}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                Heading
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_heading_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.heading} 
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{width: '100%', marginBottom: '4%'}}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Text Paragraph
                                        </InputLabel>
                                        <TextField 
                                            placeholder="" 
                                            type="text" 
                                            name={`${props.from}_text_${i}`} 
                                            variant="outlined" 
                                            sx={{
                                                width: '250px',
                                                height: '62px'
                                            }} 
                                            onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                            value={itm?.text} 
                                            required/>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Features")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Features")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                </>
            }
            {props.from === "career_&_placements" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.placementDetail?.title}
                            required/>
                    </div>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Heading
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_heading`} 
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.placementDetail?.heading} 
                            required/>
                    </div>
                    <div style={{width: '700px', marginBottom: '2%'}}>
                        <InputLabel className={classes.formInputLabel}>
                            Text Paragraph
                        </InputLabel>
                        <JoditEditor 
                            ref={editor}
                            value={props?.placementDetail?.text}
                            onChange={newText => props.textEditor(newText, props.from)}
                        />
                    </div>
                    <Typography sx={{
                        fontWeight: "bold",
                        color: 'rgb(0, 0, 0, 0.5)',
                        marginBottom: '2%'
                    }}>
                        # Career and Jobs
                    </Typography>
                    {props.placementList?.map((item, mainIndex) => (
                        item?.section_type === "Career & placements" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div key={i}
                                    style={{marginBottom: '2%', width: '700px'}}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                Top Jobs
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_job_name_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.job_name} 
                                                required/>
                                        </div>
                                        <div style={{marginLeft: '12px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Average Pay <span style={{
                                                    fontSize: '14px',
                                                    color: 'rgb(0, 0, 0, 0.4)'
                                                }}>(per annum)</span>
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="number"
                                                name={`${props.from}_pay_per_annum_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.pay_per_annum} 
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{width: '100%'}}>
                                        <InputLabel className={classes.formInputLabel}>
                                            Text Paragraph
                                        </InputLabel>
                                        <TextField 
                                            placeholder="" 
                                            type="text" 
                                            name={`${props.from}_text_${i}`}
                                            variant="outlined" 
                                            sx={{
                                                width: '512px',
                                                height: '62px'
                                            }} 
                                            onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                            value={itm?.text}
                                            required/>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Career & placements")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Career & placements")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                    
                </>
            }
            {props.from === "info_of_country" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Heading
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_heading`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.aboutDetail?.heading}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "Info of country" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} key={i} 
                                    style={{
                                        display: 'inline-block',
                                        width: 300,  
                                        marginRight: '12px',
                                        marginTop: i === 0 ? '12px' : 0,
                                        paddingBottom: '8px'}}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                Heading
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_heading_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '272px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.heading} 
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                Text
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_text_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '272px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.text}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload logo
                                            </InputLabel>
                                            <UploadImage 
                                                value={itm?.image}
                                                name={`${props.from}_logo_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Info of country")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Career & placements")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                    
                </>
            }
            {props.from === "admission_requirement" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.aboutDetail?.title}
                            required/>
                        <InputLabel className={classes.formInputLabel}>
                            Text
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_text`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.aboutDetail?.text}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "Admission requirement" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} key={i} 
                                    style={{
                                        display: 'inline-block',
                                        width: 300,  
                                        marginRight: '12px',
                                        marginTop: i === 0 ? '12px' : 0,
                                        paddingBottom: '8px'}}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                Name of requirements
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_heading_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '272px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.heading} 
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                    }}>
                                        <div style={{width: '100%'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload Icon
                                            </InputLabel>
                                            <UploadImage 
                                                value={itm?.image}
                                                name={`${props.from}_icon_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Admission requirement")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Admission requirement")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                    
                </>
            }
            {props.from === "top_courses" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }} 
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.aboutDetail?.title}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "Top courses" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} key={i} 
                                    style={{
                                        width: 700,  
                                        marginRight: '12px',
                                        marginTop: i === 0 ? '12px' : 0,
                                        paddingBottom: '8px'}}>
                                    <div style={{
                                        marginLeft: '16px',
                                        marginBottom: '8px'
                                    }}>
                                        <InputLabel className={classes.formInputLabel}>
                                          Total number of colleges
                                        </InputLabel>
                                        <TextField 
                                            placeholder="" 
                                            type="number" 
                                            name={`${props.from}_number_of_colleges_${i}`}
                                            variant="outlined" 
                                            sx={{
                                                width: '272px',
                                                height: '62px'
                                            }} 
                                            onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                            value={itm?.total_colleges} 
                                            required/>
                                    </div>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div style={{
                                            width: 324,
                                            padding: '8px 0px 8px 16px',
                                            border: '1.5px solid rgb(0, 0, 0, 0.2)',}}>
                                            <div>
                                                <InputLabel className={classes.formInputLabel}>
                                                    Type of course
                                                </InputLabel>
                                                <TextField 
                                                    placeholder="" 
                                                    type="text" 
                                                    name={`${props.from}_course_type_${i}`}
                                                    variant="outlined" 
                                                    sx={{
                                                        width: '272px',
                                                        height: '62px'
                                                    }} 
                                                    onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                    value={itm?.course_type} 
                                                    required/>
                                            </div>
                                            <div style={{width: '100%'}}>
                                                <InputLabel className={classes.formInputLabel}>
                                                    Upload Icon
                                                </InputLabel>
                                                <UploadImage 
                                                    value={itm?.course_icon}
                                                    name={`${props.from}_icon_${i}`}
                                                    handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}/>
                                            </div>
                                        </div>
                                        <div style={{
                                            width: 324,
                                            marginLeft: '12px',
                                            padding: '8px 0px 8px 16px',
                                            border: '1.5px solid rgb(0, 0, 0, 0.2)',}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Name of course
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                    type="text" 
                                                    name={`${props.from}_name_of_course_${i}`}
                                                    variant="outlined" 
                                                    sx={{
                                                        width: '272px',
                                                        height: '62px'
                                                    }} 
                                                    onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                    value={itm?.course_name} 
                                                    required/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Top courses")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Top courses")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                    
                </>
            }
            {props.from === "colleges" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.abroadCollege?.title}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "Colleges" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} key={i} 
                                    style={{
                                        width: 900,  
                                        marginTop: i === 0 ? '12px' : 0,
                                        paddingBottom: '8px'}}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                City
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_city_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '276px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.city}
                                                required/>
                                        </div>
                                        <div style={{marginLeft: '12px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Country
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_country_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '276px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.country}
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        margin: '12px 0'
                                    }}>
                                        <div className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Name of college
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_name_of_college_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.college_name}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload college logo
                                            </InputLabel>
                                            <UploadImage 
                                                value={itm?.college_logo}
                                                name={`${props.from}_college_icon_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}/>
                                        </div>
                                        <div style={{
                                            margin: '0 12px'
                                        }} className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Minimum IELTS required
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="number" 
                                                name={`${props.from}_ielts_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.minimum_ielts}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload icon
                                            </InputLabel>
                                            <UploadImage 
                                                value={itm?.ielts_icon}
                                                name={`${props.from}_ielts_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}
                                                />
                                        </div>
                                        <div className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Ranking
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="number"
                                                name={`${props.from}_ranking_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.ranking}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload icon
                                            </InputLabel>
                                            <UploadImage
                                                value={itm?.ranking_icon}
                                                name={`${props.from}_ranking_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}
                                                />
                                        </div>
                                    </div>
                                    <div>
                                        <InputLabel className={classes.formInputLabel}>
                                            CTA button name
                                        </InputLabel>
                                        <TextField 
                                            placeholder="" 
                                            type="text" 
                                            name={`${props.from}_cta_button_${i}`}
                                            variant="outlined" 
                                            sx={{
                                                width: '250px',
                                                height: '62px'
                                            }} 
                                            onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                            value={itm?.button_name}
                                            required/>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Colleges")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Colleges")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                    
                </>
            }
            {props.from === "coaching" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.abroadCollege?.title}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "Coaching" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} key={i} 
                                    style={{
                                        width: 900,  
                                        marginTop: i === 0 ? '12px' : 0,
                                        paddingBottom: '8px'}}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                City
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_city_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '276px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.city}
                                                required/>
                                        </div>
                                        <div style={{marginLeft: '12px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Country
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_country_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '276px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.country}
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        margin: '12px 0'
                                    }}>
                                        <div className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Name of coaching
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_name_of_college_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.title}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload college logo
                                            </InputLabel>
                                            <UploadImage
                                                value={itm?.image}
                                                name={`${props.from}_college_icon_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}/>
                                        </div>
                                        <div style={{
                                            margin: '0 12px'
                                        }} className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Minimum IELTS required
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="number" 
                                                name={`${props.from}_ielts_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.minimum_ielts}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload icon
                                            </InputLabel>
                                            <UploadImage 
                                                value={itm?.ielts_icon}
                                                name={`${props.from}_ielts_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}
                                                />
                                        </div>
                                        <div className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Ranking
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="number"
                                                name={`${props.from}_ranking_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.ranking}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload icon
                                            </InputLabel>
                                            <UploadImage
                                                value={itm?.ranking_icon}
                                                name={`${props.from}_ranking_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}
                                                />
                                        </div>
                                    </div>
                                    <div>
                                        <InputLabel className={classes.formInputLabel}>
                                            CTA button name
                                        </InputLabel>
                                        <TextField 
                                            placeholder="" 
                                            type="text" 
                                            name={`${props.from}_cta_button_${i}`}
                                            variant="outlined" 
                                            sx={{
                                                width: '250px',
                                                height: '62px'
                                            }} 
                                            onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                            value={itm?.button_name}
                                            required/>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Coaching")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Colleges")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                    
                </>
            }
            {props.from === "testimonials" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.testimonialDetail?.title}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "Testimonials" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} 
                                    style={{
                                        width: '100%',
                                        paddingBottom: '24px',
                                        marginTop: i === 0 ? '12px' : 0
                                        }} key={i}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                Name of student
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_student_name_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.student_name} 
                                                required/>
                                        </div>
                                        <div style={{marginLeft: '12px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Name of university
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_university_name_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.university_name}
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: '100%'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Review
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_review_${i}`} 
                                                variant="outlined" 
                                                multiline
                                                rows={7.6}
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.review}
                                                required/>
                                        </div>
                                        <div style={{marginLeft: '24px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload Image
                                            </InputLabel>
                                            <UploadBox
                                                name={`testimonials_image_${i}`}
                                                value={itm?.student_image}
                                                handleUpload={(name, value) => props.handleArrayImage(mainIndex, i, name, value)} 
                                                />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Testimonials")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Testimonials")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                </>
            }
            {props.from === "faqs" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.faqsDetail?.title}
                            required/>
                    </div>
                    <p style={{
                        textAlign: 'right',
                        width: '750px'
                    }}>Add maximum 7 FAQs</p>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "FAQs" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} key={i} 
                                    style={{
                                        paddingBottom: '12px',
                                        marginTop: i === 0 ? '12px' : 0
                                        }}>
                                    <InputLabel className={classes.formInputLabel}>
                                        Question {i + 1}
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        name={`${props.from}_question_${i}`}
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} 
                                        onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                        value={itm?.question}
                                        required/>
                                    <InputLabel className={classes.formInputLabel}>
                                        Answer {i + 1}
                                    </InputLabel>
                                    <TextField 
                                        placeholder="" 
                                        type="text" 
                                        name={`${props.from}_answer_${i}`} 
                                        variant="outlined" 
                                        sx={{
                                            width: '100%',
                                            height: '62px'
                                        }} 
                                        onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                        value={itm?.answer}
                                        required/>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "FAQs")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "FAQs")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                </>
            }
            {props.from === "blogs" &&
                <>
                    <div className="search-college-box">
                        <InputLabel className={classes.formInputLabel}>
                            Search blogs
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            // onBlur={() => setIsDropdown(false)}
                            onFocus={() => dispatch(setIsDropdown(true))}
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleSearchText(e)}
                            // value={props?.aboutDetail?.title}
                            required
                        />
                            {(blogList?.length > 0 && isDropdown) &&
                                <div className="country-group-dropdown">
                                    <InputLabel className={classes.formInputLabel}>
                                        Select blogs
                                    </InputLabel>
                                    <ul>
                                        {blogList?.map((item, index) => (
                                            <li 
                                                onClick={() => props.addMoreBlog(item)}
                                                key={index} 
                                                style={{
                                                    marginBottom: "8px",
                                                    cursor: "pointer",
                                                    width: "fit-content"
                                                }}>
                                                {index+1}. {item?.title}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            <div>
                                {props.list?.map((item, mainIndex) => (
                                    item?.section_type === "Latest blogs" && 
                                    <>
                                        {item?.blogs_ids?.map((itm, i) => (
                                            blogList?.filter((tm) => (
                                                (tm?._id === itm) || (tm?._id === itm?._id)
                                            ))?.length > 0 &&
                                            <div 
                                                key={i}
                                                className="college-card">
                                                <div
                                                    style={{height: '108px'}}
                                                    className="college-icon">
                                                    <img 
                                                        src={blogList?.filter((tm) => (
                                                            (tm?._id === itm) || (tm?._id === itm?._id)
                                                        ))[0]?.images[0]} 
                                                        style={{
                                                            width: '100%',
                                                            borderRadius: '4px'
                                                        }}
                                                    />
                                                </div>
                                                <h3>
                                                    {blogList?.filter((tm) => (
                                                        (tm?._id === itm) || (tm?._id === itm?._id)
                                                    ))[0]?.title}
                                                </h3>
                                                <div>
                                                    <h4>Category:</h4>
                                                    <p>
                                                        {blogList?.filter((tm) => (
                                                        (tm?._id === itm) || (tm?._id === itm?._id)
                                                        ))[0]?.category}
                                                    </p>
                                                    <p style={{
                                                        bottom: 12,
                                                        position: 'absolute',
                                                    }}>
                                                        {Moment(blogList?.filter((tm) => (
                                                        (tm?._id === itm) || (tm?._id === itm?._id)
                                                        ))[0]?.created_at).format("DD-MM-YYYY")}
                                                    </p>
                                                    <Button 
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '8px',
                                                            right: '8px'
                                                        }}
                                                        variant="outlined"
                                                        className={classes.removItem}
                                                        onClick={() => props.removeItemBlogs(mainIndex, "Latest blogs", i)}>
                                                        Remove
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ))}
                            </div>
                    </div>
                    {/* <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.blogDetail?.title}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "Latest blogs" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} 
                                    style={{
                                        width: '100%',
                                        paddingBottom: '24px',
                                        marginTop: i === 0 ? '12px' : 0
                                        }} key={i}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                Title
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_title_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.title} 
                                                required/>
                                        </div>
                                        <div style={{marginLeft: '12px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Date
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="date" 
                                                name={`${props.from}_date_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={Moment(itm?.date).format("YYYY-MM-DD")} 
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: '100%'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Text Paragraph
                                            </InputLabel>
                                            <JoditEditor 
                                                ref={editor}
                                                value={itm?.text}
                                                onChange={newText => props.textArrayEditor(newText, `${props.from}_text_${i}`, i, mainIndex)}
                                            />
                                        </div>
                                        <div style={{marginLeft: '24px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload Image
                                            </InputLabel>
                                            <UploadBox
                                                name={`blog_image_${i}`}
                                                value={itm?.image}
                                                handleUpload={(name, value) => props.handleArrayImage(mainIndex, i, name, value)} 
                                                />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Latest blogs")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Latest blogs")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))} */}
                </>
            }
            {props.from === "news_&_updates" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.newsDetail?.title}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "News & updates" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} 
                                    style={{
                                        width: '100%',
                                        paddingBottom: '24px',
                                        marginTop: i === 0 ? '12px' : 0
                                        }} key={i}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                Heading
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_heading_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.heading} 
                                                required/>
                                        </div>
                                        <div style={{marginLeft: '12px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Date
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="date" 
                                                name={`${props.from}_date_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={Moment(itm?.date).format("YYYY-MM-DD")}
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: '100%'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Text Paragraph
                                            </InputLabel>
                                            <JoditEditor 
                                                ref={editor}
                                                value={itm?.text}
                                                onChange={newText => props.textArrayEditor(newText, `${props.from}_text_${i}`, i, mainIndex)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "News & updates")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "News & updates")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                </>
            }
            {props.from === "study_in" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.aboutDetail?.title}
                            required/>
                    </div>
                    <div style={{
                            width: '100%',
                            paddingBottom: '24px',
                            }}>
                        <div>
                            <InputLabel className={classes.formInputLabel}>
                                Heading
                            </InputLabel>
                            <TextField 
                                placeholder="" 
                                type="text" 
                                name={`${props.from}_heading`} 
                                variant="outlined" 
                                sx={{
                                    width: '250px',
                                    height: '62px'
                                }}
                                onChange={(e) => props.handleBChnage(e)}
                                value={props?.aboutDetail?.heading} 
                                required/>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '100%'}}>
                                <InputLabel className={classes.formInputLabel}>
                                    Text Paragraph
                                </InputLabel>
                                <JoditEditor 
                                    ref={editor}
                                    value={props?.aboutDetail?.text}
                                    onChange={newText => props.textEditor(newText, props.from)}
                                />
                            </div>
                            <div style={{marginLeft: '24px'}}>
                                <InputLabel className={classes.formInputLabel}>
                                    Upload Image
                                </InputLabel>
                                <UploadBox
                                    name="study_in_image"
                                    value={props?.aboutDetail?.image}
                                    handleUpload={(name, value) => props.handleImageUploads(name, value)}
                                    />
                            </div>
                        </div>
                    </div>
                </>
            }
            {props.from === "benefits" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.testimonialDetail?.title}
                            required/>
                        <InputLabel className={classes.formInputLabel}>
                            Heading
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_heading`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.testimonialDetail?.heading}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "Benefits" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} 
                                    style={{
                                        width: '100%',
                                        paddingBottom: '24px',
                                        marginTop: i === 0 ? '12px' : 0
                                        }} key={i}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                Title
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_title_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.title} 
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: '100%'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Text Paragraph
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_text_${i}`} 
                                                variant="outlined" 
                                                multiline
                                                rows={7.6}
                                                sx={{
                                                    width: '100%',
                                                    height: '62px'
                                                }}
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.text}
                                                required/>
                                        </div>
                                        <div style={{marginLeft: '24px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload Image
                                            </InputLabel>
                                            <UploadBox
                                                name={`benefits_image_${i}`}
                                                value={itm?.image}
                                                handleUpload={(name, value) => props.handleArrayImage(mainIndex, i, name, value)} 
                                                />
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Benefits")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Benefits")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                </>
            }
            {props.from === "top_colleges" &&
                <>
                    <div className="search-college-box">
                        <InputLabel className={classes.formInputLabel}>
                            Search colleges or universities
                        </InputLabel> 
                        <TextField 
                            placeholder="" 
                            type="text" 
                            // onBlur={() => setIsDropdown(false)}
                            onFocus={() => dispatch(setIsDropdown(true))}
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '100%',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleSearchText(e)}
                            // value={props?.aboutDetail?.title}
                            required/>
                            {(universityData?.length > 0 && isDropdown) &&
                                <div className="country-group-dropdown">
                                    <InputLabel className={classes.formInputLabel}>
                                        Select colleges or universities
                                    </InputLabel>
                                    <ul>
                                        {universityData?.map((item, index) => (
                                            <li 
                                                onClick={() => props.addMoreCollege(item)}
                                                key={index} 
                                                style={{
                                                    marginBottom: "8px",
                                                    cursor: "pointer",
                                                    width: "fit-content"
                                                }}>
                                                {index+1}. {item?.university_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            <div>
                                {props.list?.map((item, mainIndex) => (
                                    item?.section_type === "Top colleges" && 
                                    <>
                                        {item?.university_ids?.map((itm, i) => (
                                            totalUniversity?.filter((tm) => (
                                                (tm?._id === itm) || (tm?._id === itm?._id)
                                            ))?.length > 0 &&
                                            <div 
                                                key={i}
                                                className="college-card">
                                                <div className="college-icon">
                                                    <img src={totalUniversity?.filter((tm) => (
                                                        (tm?._id === itm) || (tm?._id === itm?._id)
                                                    ))[0]?.uploads?.university_logo} />
                                                </div>
                                                <h3>
                                                    {totalUniversity?.filter((tm) => (
                                                        (tm?._id === itm) || (tm?._id === itm?._id)
                                                    ))[0]?.university_name}
                                                </h3>
                                                <div>
                                                    <h4>Address:</h4>
                                                    <p>
                                                        {totalUniversity?.filter((tm) => (
                                                        (tm?._id === itm) || (tm?._id === itm?._id)
                                                        ))[0]?.address
                                                        }
                                                    </p>
                                                    <Button 
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '8px',
                                                            right: '8px'
                                                        }}
                                                        variant="outlined"
                                                        className={classes.removItem} 
                                                        onClick={() => props.removeItemCollege(mainIndex, "Top Colleges", i)}>
                                                        Remove
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ))}
                            </div>
                    </div>
                    {/* <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.aboutDetail?.title}
                            required/>
                    </div> */}
                    {/* {props.list.map((item, mainIndex) => (
                        item?.section_type === "Top colleges" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} key={i} 
                                    style={{
                                        width: 900,  
                                        marginTop: i === 0 ? '12px' : 0,
                                        paddingBottom: '8px'}}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div>
                                            <InputLabel className={classes.formInputLabel}>
                                                City
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_city_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '276px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.city}
                                                required/>
                                        </div>
                                        <div style={{marginLeft: '12px'}}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Country
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_country_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '276px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.country}
                                                required/>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        margin: '12px 0'
                                    }}>
                                        <div className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Name of college
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_name_of_college_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.college_name}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload college logo
                                            </InputLabel>
                                            <UploadImage 
                                                value={itm?.college_logo}
                                                name={`${props.from}_college_icon_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}/>
                                        </div>
                                        <div style={{
                                            margin: '0 12px'
                                        }} className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Eligibility Criteria
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_ielts_${i}`}
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.minimum_ielts}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload icon
                                            </InputLabel>
                                            <UploadImage 
                                                value={itm?.ielts_icon}
                                                name={`${props.from}_ielts_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}
                                                />
                                        </div>
                                        <div className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Ranking
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="number"
                                                name={`${props.from}_ranking_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)}
                                                value={itm?.ranking}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload icon
                                            </InputLabel>
                                            <UploadImage
                                                value={itm?.ranking_icon}
                                                name={`${props.from}_ranking_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}
                                                />
                                        </div>
                                    </div>
                                    <div>
                                        <InputLabel className={classes.formInputLabel}>
                                            CTA button name
                                        </InputLabel>
                                        <TextField 
                                            placeholder="" 
                                            type="text" 
                                            name={`${props.from}_cta_button_${i}`}
                                            variant="outlined" 
                                            sx={{
                                                width: '250px',
                                                height: '62px'
                                            }} 
                                            onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                            value={itm?.button_name}
                                            required/>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Indian colleges")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Career & placements")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))} */}
                    
                </>
            }
            {props.from === "top_study_places" &&
                <>
                    <div>
                        <InputLabel className={classes.formInputLabel}>
                            Title of section
                        </InputLabel>
                        <TextField 
                            placeholder="" 
                            type="text" 
                            name={`${props.from}_title`}
                            variant="outlined" 
                            sx={{
                                width: '250px',
                                height: '62px'
                            }}
                            onChange={(e) => props.handleBChnage(e)}
                            value={props?.aboutDetail?.title}
                            required/>
                    </div>
                    {props.list.map((item, mainIndex) => (
                        item?.section_type === "Top study places" &&
                        <>
                            {item?.options?.map((itm, i) => (
                                <div className={classes.featureBox} key={i} 
                                    style={{
                                        display: 'inline-block',
                                        width: 304,  
                                        marginRight: '8px',
                                        marginTop: i === 0 ? '12px' : 0,
                                        paddingBottom: '8px'}}>
                                    <div style={{
                                        display: 'flex',
                                        margin: '12px 0'
                                    }}>
                                        <div className={classes.signleCard}>
                                            <InputLabel className={classes.formInputLabel}>
                                                Name of City
                                            </InputLabel>
                                            <TextField 
                                                placeholder="" 
                                                type="text" 
                                                name={`${props.from}_city_${i}`} 
                                                variant="outlined" 
                                                sx={{
                                                    width: '250px',
                                                    height: '62px'
                                                }} 
                                                onChange={(e) => props.handleArray(e, i, item?.section_type, mainIndex)} 
                                                value={itm?.city}
                                                required/>
                                            <InputLabel className={classes.formInputLabel}>
                                                Upload college logo
                                            </InputLabel>
                                            <UploadImage 
                                                value={itm?.image}
                                                name={`${props.from}_icon_${i}`}
                                                handleArrayImage={(name, value) => props.handleArrayImage(mainIndex, i, name, value)}/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <Button 
                                    className={classes.addFeature} 
                                    onClick={() => props.addMore(mainIndex, "Top study places")}>
                                    + Add More
                                </Button>
                                <Button 
                                    variant="outlined"
                                    className={classes.removItem} 
                                    onClick={() => props.removeItem(mainIndex, "Top study places")}>
                                    Remove
                                </Button>
                            </div>
                        </>
                    ))}
                    
                </>
            }

        </div>
    )
}

const UploadImage = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState(false);
    const [btnName, setBtnName] = React.useState("");
    const handleUploadChange = async(event) => {
        if(event.target.files[0]?.type !== "image/png" &&
        event.target.files[0]?.type !== "image/jpeg"){
            toast.error("Invalid file type");
            return;
        }
        if(event.target.files[0]?.size > 1049000){
            toast.error("Image size must be less than 1MB");
            return;
        }else{
            setBtnName(props.name);
            const response = await getImageUrls({
                image: event.target.files[0],
                setLoading,
                setErrors,
            });
            props.handleArrayImage(event.target.name, response?.data?.urls[0])
        }
    }

    return (
        <div style={{
            padding: 0}}>
            <label htmlFor={props.name} 
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                justifyContent: 'center'
            }}>
                <img src={upload} alt="upload" width="24" height="32" />
                <Typography component="h2" variant="h2" sx={{
                    fontSize: '12px',
                    margin: '6px 0 12px 0',
                    textAlign: 'center',
                    fontWeight: 'bold'
                    }}>
                    {props?.value ? (props?.value?.split("/")[3]?.split("-")[1]) : 
                        "PNG or JPG"}
                </Typography>
                <Input
                    id={props.name}
                    name={props.name}
                    type="file"
                    accept="application/pdf"
                    sx={{display: 'none'}}
                    onChange={(e) => {handleUploadChange(e)}}
                />
                <Button component="div" className={classes.upload}>
                    <UploadFileIcon fontSize="15" />
                    {(loading && (btnName === props.name)) ? 
                    <img src={Loader} width="12" alt="Loader" /> : 
                        <span>{'Upload'}</span>}
                </Button>
            </label>
        </div>
    )
}

export default CustomPageForm;