import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customPageServices from "./customPageServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  totalCount: 0,
  searchText: "",
  pageType: "",
  customPageData: null,
  customPageById: null,
  groupList: null,
  mbbAbroadInfo: null
};

export const addNewCustomPages = createAsyncThunk(
    "customPages/addNewCustomPages",
    async (params, thunkAPI) => {
      try {
        return await customPageServices.addNewCustomPages(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getCustomPages = createAsyncThunk(
    "customPages/getCustomPages",
    async (params, thunkAPI) => {
      try {
        return await customPageServices.getCustomPages(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getCustomPagesById = createAsyncThunk(
    "customPages/getCustomPagesById",
    async (params, thunkAPI) => {
      try {
        return await customPageServices.getCustomPagesById(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const deleteCustomPage = createAsyncThunk(
    "customPages/deleteCustomPage",
    async (params, thunkAPI) => {
      try {
        return await customPageServices.deleteCustomPage(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const updateCustomPages = createAsyncThunk(
    "customPages/updateCustomPages",
    async (params, thunkAPI) => {
      try {
        return await customPageServices.updateCustomPages(params);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getGroupList = createAsyncThunk(
  "customPages/getGroupList",
  async (params, thunkAPI) => {
    try {
      return await customPageServices.getGroupList(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addMBBSAbroadInfo = createAsyncThunk(
  "customPages/addMBBSAbroadInfo",
  async (params, thunkAPI) => {
    try {
      return await customPageServices.addMBBSAbroadInfo(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMBBSAbroadInfo = createAsyncThunk(
  "customPages/getMBBSAbroadInfo",
  async (params, thunkAPI) => {
    try {
      return await customPageServices.getMBBSAbroadInfo(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const customPageSlice = createSlice({
  name: "customPage",
  initialState,
  reducers: {
    setCustomPageSearchText: (state, action) => {
        state.searchText = action.payload;
    },
    setPageType: (state, action) => {
        state.pageType = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewCustomPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewCustomPages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addNewCustomPages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(getCustomPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomPages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCustomPages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.totalCount = action.payload.data?.totalCount;
        state.customPageData = action.payload.data.result;
      })
      .addCase(getCustomPagesById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomPagesById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCustomPagesById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.customPageById = action.payload.data;
      })
      .addCase(deleteCustomPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCustomPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCustomPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(updateCustomPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCustomPages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCustomPages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(getGroupList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGroupList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getGroupList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.groupList = action.payload.data.result;
      })
      .addCase(addMBBSAbroadInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMBBSAbroadInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addMBBSAbroadInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
      })
      .addCase(getMBBSAbroadInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMBBSAbroadInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getMBBSAbroadInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload.message;
        state.mbbAbroadInfo = action.payload.data;
      })
  },
});

export const { 
    setPageType,
    setCustomPageSearchText } = customPageSlice.actions;

export default customPageSlice.reducer;
